import { defineAsyncComponent } from 'vue'
export default {
  account: defineAsyncComponent(() => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/layouts/account.vue")),
  admin: defineAsyncComponent(() => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/layouts/admin.vue")),
  blank: defineAsyncComponent(() => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/layouts/blank.vue")),
  default: defineAsyncComponent(() => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/layouts/default.vue")),
  form: defineAsyncComponent(() => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/layouts/form.vue")),
  signin: defineAsyncComponent(() => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/layouts/signin.vue")),
  "claim-lab": defineAsyncComponent(() => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/claim-lab/layouts/claim-lab.vue")),
  "auth-public": defineAsyncComponent(() => import("#build/layouts.public.c8b523fc.vue"))
}